<template>
  <div class="WebCastHeader">
    <h2 class="webcastTitle">{{ data.title }}</h2>
    <div v-if="slides.length">
      <VueSlickCarouselc  :lazyLoad="'progressive'" class="WebCastHeader__carousel content-margin" v-bind="settings">
        <div class="WebCastHeader__carousel-slide" v-for="(slide, i) in slides" :key="i">
          <div class="media-content" @click="goToVideo(slide.texts._id)">
            <img :src="urlCloudFront($screen.width > 430 ? slides[i].poster.desktop : slides[i].poster.mobile)" alt="poster" />
            <div class="wrapper-btn" v-if="slide.type == 'video'">
              <button class="btn-play" @click="openModal">
                <img :src="urlCloudFront('https://new-web-site-bucket.s3.amazonaws.com/website/blog/iconPlay.svg')" class="btn-play" />
              </button>
            </div>
          </div>
        </div>
      </VueSlickCarouselc>
    </div>
    <postModal :showModal="showModal" ref="postModal" />
  </div>
</template>
<script>
import VueSlickCarouselc from "vue-slick-carousel";
import postModal from "../modals/PostModal.vue";
export default {
  components: { VueSlickCarouselc, postModal },
  props: ["data"],
  data: function () {
    return {
      settings: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 6000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 1,
              dots: true,
            },
          },
        ],
      },
      showModal: true,
    };
  },
  methods: {
    goToVideo(id) {
      const videoSelected = document.querySelector(`[data-textId=textId-${id}]`);
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      window.scrollTo({
        top: documentHeight - windowHeight,
        behavior: "smooth",
      });
      setTimeout(() => {
        videoSelected.click();
      }, 700);
    },
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    openModal(evt) {
      const isTextClicked = evt.target.classList.contains("slide__text-short");
      if (isTextClicked && this.$screen.width < 768) {
        return;
      }
      this.$refs.postModal.openYoutubeModal();
    },
  },
  computed: {
    slides: function () {
      return this.data.multimedia.map((media) => {
        return {
          type: ["youtube", "video"].includes(media.platformType) ? "video" : "image",
          texts: media.texts,
          media: media.url.desktop,
          poster: media.poster,
        };
      });
    },
  },
};
</script>
<style lang="scss">
.WebCastHeader {
  margin: 72px 0;
  .webcastTitle {
    color: $primary-color;
    text-align: center;
  }

  &__carousel {
    &-slide {
      position: relative;
      .media-content {
        box-shadow: 0px 1px 7px 0px #000000d9;
        position: relative;
        transition: visibility 0.3s ease-in;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 2px;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.15) 70.16%, rgba(0, 0, 0, 0.7) 89%);
        }
        video,
        img:not(.btn-play) {
          width: 100%;
          height: 100%;
        }
        .wrapper-btn {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          right: 5px;
          bottom: 5px;
          z-index: 50;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .btn-play {
            border: none;
            background: transparent;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 20;
            cursor: pointer;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .slick-dots {
      bottom: -40px;
    }
  }
  .slick-dots {
    position: absolute;
  }
  .slick-dots li button {
    font-size: 15px;
    color: $primary_color;
  }
  .slick-dots li {
    margin: 0 5px !important;
  }
  .slick-dots li button {
    font-size: 15px;
    color: transparent;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background-color: #d9d9d980;
    transition: width 0.2s ease-in-out;
  }

  .slick-dots li.slick-active {
    width: 30px;
    button {
      background-color: $primary_color;
      width: 100%;
    }
  }

  .slick-dots li button:before {
    color: transparent;
    opacity: 0;
  }

  @media (max-width: 600px) {
    .media-content {
      .wrapper-btn {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        bottom: 5px;
        z-index: 50;
        top: 50%;
        left: 50%;
        transform: translate(-350%, 80%);
        .btn-play {
          border: none;
          background: transparent;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 20;
          cursor: pointer;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  @include Desktop {
    .media-content {
      position: relative;
      video,
      img {
        border-radius: 10px;
      }
      &::after {
        border-radius: 10px;
      }
      .wrapper-btn {
        width: 100px;
        height: 100px;
        top: 87%;
        left: 100px;
      }
    }
    .slick-dots {
      bottom: 30px;
    }
    .slick-list {
      border-radius: 15px;
    }
  }
}
</style>
